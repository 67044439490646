import * as S from 'apps/cliniko/style'

const Heading = ({ as, level = 3, styleLevel = level, ...props }) => {
  const Component = as || `h${level}`
  return <Component css={[{ position: 'relative' }, stylesByLevel[styleLevel]]} {...props} />
}

export const stylesByLevel = {
  1: {
    fontWeight: 600,
    fontSize: S.rem(28),
    lineHeight: S.unit(4),
    bottom: S.rem(2),
  },
  2: {
    fontWeight: 500,
    fontSize: S.rem(23),
    lineHeight: S.unit(4),
    bottom: S.rem(1),
  },
  3: {
    fontWeight: 600,
    fontSize: S.rem(19),
    lineHeight: S.unit(3),
    bottom: S.rem(3),
  },
  4: {
    fontWeight: 500,
    fontSize: S.rem(16),
    lineHeight: S.unit(3),
    bottom: S.rem(2),
  },
}

export default Heading
