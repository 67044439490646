/* eslint-disable camelcase */
import address_book from './icons/address-book.svg?forReact'
import address_book_2x from './icons/address-book_2x.svg?forReact'
import align_center from './icons/align-center.svg?forReact'
import align_center_2x from './icons/align-center_2x.svg?forReact'
import align_left from './icons/align-left.svg?forReact'
import align_left_2x from './icons/align-left_2x.svg?forReact'
import align_right from './icons/align-right.svg?forReact'
import align_right_2x from './icons/align-right_2x.svg?forReact'
import archive from './icons/archive.svg?forReact'
import archive_2x from './icons/archive_2x.svg?forReact'
import arrow_anticlockwise from './icons/arrow-anticlockwise.svg?forReact'
import arrow_anticlockwise_2x from './icons/arrow-anticlockwise_2x.svg?forReact'
import arrow_clockwise from './icons/arrow-clockwise.svg?forReact'
import arrow_clockwise_2x from './icons/arrow-clockwise_2x.svg?forReact'
import arrow_down from './icons/arrow-down.svg?forReact'
import arrow_down_2x from './icons/arrow-down_2x.svg?forReact'
import arrow_left from './icons/arrow-left.svg?forReact'
import arrow_left_2x from './icons/arrow-left_2x.svg?forReact'
import arrow_right from './icons/arrow-right.svg?forReact'
import arrow_right_2x from './icons/arrow-right_2x.svg?forReact'
import arrow_up from './icons/arrow-up.svg?forReact'
import arrow_up_2x from './icons/arrow-up_2x.svg?forReact'
import at from './icons/at.svg?forReact'
import at_2x from './icons/at_2x.svg?forReact'
import bar_chart from './icons/bar-chart.svg?forReact'
import bar_chart_2x from './icons/bar-chart_2x.svg?forReact'
import barcode from './icons/barcode.svg?forReact'
import barcode_2x from './icons/barcode_2x.svg?forReact'
import bell_1 from './icons/bell-1.svg?forReact'
import bell_1_2x from './icons/bell-1_2x.svg?forReact'
import bell_filled_alert from './icons/bell-filled-alert.svg?forReact'
import bell_filled_alert_2x from './icons/bell-filled-alert_2x.svg?forReact'
import bell_filled from './icons/bell-filled.svg?forReact'
import bell_filled_2x from './icons/bell-filled_2x.svg?forReact'
import bell_muted from './icons/bell-muted.svg?forReact'
import bell_muted_2x from './icons/bell-muted_2x.svg?forReact'
import bell from './icons/bell.svg?forReact'
import bell_2x from './icons/bell_2x.svg?forReact'
import bin from './icons/bin.svg?forReact'
import bin_2x from './icons/bin_2x.svg?forReact'
import bold from './icons/bold.svg?forReact'
import bold_2x from './icons/bold_2x.svg?forReact'
import bookmark_clock_filled from './icons/bookmark-clock-filled.svg?forReact'
import bookmark_clock_filled_2x from './icons/bookmark-clock-filled_2x.svg?forReact'
import bookmark from './icons/bookmark.svg?forReact'
import bookmark_2x from './icons/bookmark_2x.svg?forReact'
import box_arrow_top_right from './icons/box-arrow-top-right.svg?forReact'
import box_arrow_top_right_2x from './icons/box-arrow-top-right_2x.svg?forReact'
import bullet_list from './icons/bullet-list.svg?forReact'
import bullet_list_2x from './icons/bullet-list_2x.svg?forReact'
import calendar_arrow_left from './icons/calendar-arrow-left.svg?forReact'
import calendar_arrow_left_2x from './icons/calendar-arrow-left_2x.svg?forReact'
import calendar_arrow_right from './icons/calendar-arrow-right.svg?forReact'
import calendar_arrow_right_2x from './icons/calendar-arrow-right_2x.svg?forReact'
import calendar_tick from './icons/calendar-tick.svg?forReact'
import calendar_tick_2x from './icons/calendar-tick_2x.svg?forReact'
import calendar from './icons/calendar.svg?forReact'
import calendar_2x from './icons/calendar_2x.svg?forReact'
import campfire from './icons/campfire.svg?forReact'
import campfire_2x from './icons/campfire_2x.svg?forReact'
import cancel from './icons/cancel.svg?forReact'
import cancel_2x from './icons/cancel_2x.svg?forReact'
import chat_bubble from './icons/chat-bubble.svg?forReact'
import chat_bubble_2x from './icons/chat-bubble_2x.svg?forReact'
import chevron_down from './icons/chevron-down.svg?forReact'
import chevron_down_2x from './icons/chevron-down_2x.svg?forReact'
import chevron_left from './icons/chevron-left.svg?forReact'
import chevron_left_2x from './icons/chevron-left_2x.svg?forReact'
import chevron_right from './icons/chevron-right.svg?forReact'
import chevron_right_2x from './icons/chevron-right_2x.svg?forReact'
import chevron_up from './icons/chevron-up.svg?forReact'
import chevron_up_2x from './icons/chevron-up_2x.svg?forReact'
import circle_fill from './icons/circle-fill.svg?forReact'
import circle_fill_2x from './icons/circle-fill_2x.svg?forReact'
import circle from './icons/circle.svg?forReact'
import circle_2x from './icons/circle_2x.svg?forReact'
import clock from './icons/clock.svg?forReact'
import clock_2x from './icons/clock_2x.svg?forReact'
import close_left from './icons/close-left.svg?forReact'
import close_left_2x from './icons/close-left_2x.svg?forReact'
import close_right from './icons/close-right.svg?forReact'
import close_right_2x from './icons/close-right_2x.svg?forReact'
import cloud_strikethrough from './icons/cloud-strikethrough.svg?forReact'
import cloud_strikethrough_2x from './icons/cloud-strikethrough_2x.svg?forReact'
import cloud from './icons/cloud.svg?forReact'
import cloud_2x from './icons/cloud_2x.svg?forReact'
import code from './icons/code.svg?forReact'
import code_2x from './icons/code_2x.svg?forReact'
import coffee_cup from './icons/coffee-cup.svg?forReact'
import coffee_cup_2x from './icons/coffee-cup_2x.svg?forReact'
import cog from './icons/cog.svg?forReact'
import cog_2x from './icons/cog_2x.svg?forReact'
import credit_card from './icons/credit-card.svg?forReact'
import credit_card_2x from './icons/credit-card_2x.svg?forReact'
import customise from './icons/customise.svg?forReact'
import customise_2x from './icons/customise_2x.svg?forReact'
import dashboard from './icons/dashboard.svg?forReact'
import dashboard_2x from './icons/dashboard_2x.svg?forReact'
import document from './icons/document.svg?forReact'
import document_2x from './icons/document_2x.svg?forReact'
import download from './icons/download.svg?forReact'
import download_2x from './icons/download_2x.svg?forReact'
import draft from './icons/draft.svg?forReact'
import draft_2x from './icons/draft_2x.svg?forReact'
import drag_and_drop from './icons/drag-and-drop.svg?forReact'
import drag_and_drop_2x from './icons/drag-and-drop_2x.svg?forReact'
import duplicate from './icons/duplicate.svg?forReact'
import duplicate_2x from './icons/duplicate_2x.svg?forReact'
import envelope_closed from './icons/envelope-closed.svg?forReact'
import envelope_closed_2x from './icons/envelope-closed_2x.svg?forReact'
import eye from './icons/eye.svg?forReact'
import eye_2x from './icons/eye_2x.svg?forReact'
import filter from './icons/filter.svg?forReact'
import filter_2x from './icons/filter_2x.svg?forReact'
import flag from './icons/flag.svg?forReact'
import flag_2x from './icons/flag_2x.svg?forReact'
import folder from './icons/folder.svg?forReact'
import folder_2x from './icons/folder_2x.svg?forReact'
import forward from './icons/forward.svg?forReact'
import forward_2x from './icons/forward_2x.svg?forReact'
import four_dots from './icons/four-dots.svg?forReact'
import four_dots_2x from './icons/four-dots_2x.svg?forReact'
import github from './icons/github.svg?forReact'
import google from './icons/google.svg?forReact'
import group from './icons/group.svg?forReact'
import group_2x from './icons/group_2x.svg?forReact'
import hamburger from './icons/hamburger.svg?forReact'
import hamburger_2x from './icons/hamburger_2x.svg?forReact'
import heart_fill from './icons/heart-fill.svg?forReact'
import heart_fill_2x from './icons/heart-fill_2x.svg?forReact'
import heart from './icons/heart.svg?forReact'
import heart_2x from './icons/heart_2x.svg?forReact'
import hidden from './icons/hidden.svg?forReact'
import hidden_2x from './icons/hidden_2x.svg?forReact'
import history from './icons/history.svg?forReact'
import history_2x from './icons/history_2x.svg?forReact'
import hour_glass from './icons/hour-glass.svg?forReact'
import hour_glass_2x from './icons/hour-glass_2x.svg?forReact'
import image from './icons/image.svg?forReact'
import image_2x from './icons/image_2x.svg?forReact'
import import_icon from './icons/import.svg?forReact'
import import_icon_2x from './icons/import_2x.svg?forReact'
import indent_less from './icons/indent-less.svg?forReact'
import indent_less_2x from './icons/indent-less_2x.svg?forReact'
import indent_more from './icons/indent-more.svg?forReact'
import indent_more_2x from './icons/indent-more_2x.svg?forReact'
import info_circle from './icons/info-circle.svg?forReact'
import info_circle_2x from './icons/info-circle_2x.svg?forReact'
import invoice from './icons/invoice.svg?forReact'
import invoice_2x from './icons/invoice_2x.svg?forReact'
import italic from './icons/italic.svg?forReact'
import italic_2x from './icons/italic_2x.svg?forReact'
import keyboard_shortcuts from './icons/keyboard-shortcuts.svg?forReact'
import keyboard_shortcuts_2x from './icons/keyboard-shortcuts_2x.svg?forReact'
import label from './icons/label.svg?forReact'
import label_2x from './icons/label_2x.svg?forReact'
import lifebuoy from './icons/lifebuoy.svg?forReact'
import lifebuoy_2x from './icons/lifebuoy_2x.svg?forReact'
import line_break from './icons/line-break.svg?forReact'
import line_break_2x from './icons/line-break_2x.svg?forReact'
import link from './icons/link.svg?forReact'
import link_2x from './icons/link_2x.svg?forReact'
import location from './icons/location.svg?forReact'
import location_2x from './icons/location_2x.svg?forReact'
import log_out from './icons/log-out.svg?forReact'
import log_out_2x from './icons/log-out_2x.svg?forReact'
import magnifying_glass from './icons/magnifying-glass.svg?forReact'
import magnifying_glass_2x from './icons/magnifying-glass_2x.svg?forReact'
import maximize from './icons/maximize.svg?forReact'
import maximize_2x from './icons/maximize_2x.svg?forReact'
import merge from './icons/merge.svg?forReact'
import merge_2x from './icons/merge_2x.svg?forReact'
import minimize from './icons/minimize.svg?forReact'
import minimize_2x from './icons/minimize_2x.svg?forReact'
import minus from './icons/minus.svg?forReact'
import minus_2x from './icons/minus_2x.svg?forReact'
import moon from './icons/moon.svg?forReact'
import moon_2x from './icons/moon_2x.svg?forReact'
import move_horizontal from './icons/move-horizontal.svg?forReact'
import move_horizontal_2x from './icons/move-horizontal_2x.svg?forReact'
import move_vertical from './icons/move-vertical.svg?forReact'
import move_vertical_2x from './icons/move-vertical_2x.svg?forReact'
import narrow from './icons/narrow.svg?forReact'
import narrow_2x from './icons/narrow_2x.svg?forReact'
import numbered_list from './icons/numbered-list.svg?forReact'
import numbered_list_2x from './icons/numbered-list_2x.svg?forReact'
import one_column from './icons/one-column.svg?forReact'
import one_column_2x from './icons/one-column_2x.svg?forReact'
import open_book from './icons/open-book.svg?forReact'
import open_book_2x from './icons/open-book_2x.svg?forReact'
import open_left from './icons/open-left.svg?forReact'
import open_left_2x from './icons/open-left_2x.svg?forReact'
import open_right from './icons/open-right.svg?forReact'
import open_right_2x from './icons/open-right_2x.svg?forReact'
import options from './icons/options.svg?forReact'
import options_2x from './icons/options_2x.svg?forReact'
import padlock from './icons/padlock.svg?forReact'
import padlock_2x from './icons/padlock_2x.svg?forReact'
import paper_clip from './icons/paper-clip.svg?forReact'
import paper_clip_2x from './icons/paper-clip_2x.svg?forReact'
import paper_plane from './icons/paper-plane.svg?forReact'
import paper_plane_2x from './icons/paper-plane_2x.svg?forReact'
import parapgraph from './icons/parapgraph.svg?forReact'
import parapgraph_2x from './icons/parapgraph_2x.svg?forReact'
import passkey from './icons/passkey.svg?forReact'
import pencil from './icons/pencil.svg?forReact'
import pencil_2x from './icons/pencil_2x.svg?forReact'
import people from './icons/people.svg?forReact'
import people_2x from './icons/people_2x.svg?forReact'
import phone from './icons/phone.svg?forReact'
import phone_2x from './icons/phone_2x.svg?forReact'
import pin from './icons/pin.svg?forReact'
import pin_2x from './icons/pin_2x.svg?forReact'
import plus from './icons/plus.svg?forReact'
import plus_2x from './icons/plus_2x.svg?forReact'
import pointer from './icons/pointer.svg?forReact'
import pointer_2x from './icons/pointer_2x.svg?forReact'
import print from './icons/print.svg?forReact'
import print_2x from './icons/print_2x.svg?forReact'
import products from './icons/products.svg?forReact'
import products_2x from './icons/products_2x.svg?forReact'
import question_circle from './icons/question-circle.svg?forReact'
import question_circle_2x from './icons/question-circle_2x.svg?forReact'
import question_mark from './icons/question-mark.svg?forReact'
import question_mark_2x from './icons/question-mark_2x.svg?forReact'
import quote from './icons/quote.svg?forReact'
import quote_2x from './icons/quote_2x.svg?forReact'
import replace from './icons/replace.svg?forReact'
import replace_2x from './icons/replace_2x.svg?forReact'
import reply from './icons/reply.svg?forReact'
import reply_2x from './icons/reply_2x.svg?forReact'
import save from './icons/save.svg?forReact'
import save_2x from './icons/save_2x.svg?forReact'
import smiley from './icons/smiley.svg?forReact'
import smiley_2x from './icons/smiley_2x.svg?forReact'
import speech_bubble from './icons/speech-bubble.svg?forReact'
import speech_bubble_2x from './icons/speech-bubble_2x.svg?forReact'
import square from './icons/square.svg?forReact'
import square_2x from './icons/square_2x.svg?forReact'
import stacked_rectangles from './icons/stacked-rectangles.svg?forReact'
import stacked_rectangles_2x from './icons/stacked-rectangles_2x.svg?forReact'
import star from './icons/star.svg?forReact'
import star_2x from './icons/star_2x.svg?forReact'
import sweep from './icons/sweep.svg?forReact'
import sweep_2x from './icons/sweep_2x.svg?forReact'
import table from './icons/table.svg?forReact'
import table_2x from './icons/table_2x.svg?forReact'
import text_bigger from './icons/text-bigger.svg?forReact'
import text_bigger_2x from './icons/text-bigger_2x.svg?forReact'
import text_size from './icons/text-size.svg?forReact'
import text_size_2x from './icons/text-size_2x.svg?forReact'
import text_smaller from './icons/text-smaller.svg?forReact'
import text_smaller_2x from './icons/text-smaller_2x.svg?forReact'
import three_dots from './icons/three-dots.svg?forReact'
import three_dots_2x from './icons/three-dots_2x.svg?forReact'
import tick_circle from './icons/tick-circle.svg?forReact'
import tick_circle_2x from './icons/tick-circle_2x.svg?forReact'
import tick from './icons/tick.svg?forReact'
import tick_2x from './icons/tick_2x.svg?forReact'
import timezone from './icons/timezone.svg?forReact'
import timezone_2x from './icons/timezone_2x.svg?forReact'
import triangle from './icons/triangle.svg?forReact'
import triangle_2x from './icons/triangle_2x.svg?forReact'
import tt from './icons/tt.svg?forReact'
import tt_2x from './icons/tt_2x.svg?forReact'
import twitter from './icons/twitter.svg?forReact'
import two_columns_minimized from './icons/two-columns-minimized.svg?forReact'
import two_columns_minimized_2x from './icons/two-columns-minimized_2x.svg?forReact'
import two_columns from './icons/two-columns.svg?forReact'
import two_columns_2x from './icons/two-columns_2x.svg?forReact'
import unarchive from './icons/unarchive.svg?forReact'
import unarchive_2x from './icons/unarchive_2x.svg?forReact'
import underline from './icons/underline.svg?forReact'
import underline_2x from './icons/underline_2x.svg?forReact'
import upload from './icons/upload.svg?forReact'
import upload_2x from './icons/upload_2x.svg?forReact'
import user from './icons/user.svg?forReact'
import user_2x from './icons/user_2x.svg?forReact'
import wide from './icons/wide.svg?forReact'
import wide_2x from './icons/wide_2x.svg?forReact'
import x_circle from './icons/x-circle.svg?forReact'
import x_circle_2x from './icons/x-circle_2x.svg?forReact'
import x_small from './icons/x-small.svg?forReact'
import x_small_2x from './icons/x-small_2x.svg?forReact'
import x from './icons/x.svg?forReact'
import x_2x from './icons/x_2x.svg?forReact'
import { makeIcon } from './make-icon'

export const AddressBook = makeIcon([address_book, address_book_2x])
export const AlignCenter = makeIcon([align_center, align_center_2x])
export const AlignLeft = makeIcon([align_left, align_left_2x])
export const AlignRight = makeIcon([align_right, align_right_2x])
export const Archive = makeIcon([archive, archive_2x])
export const ArrowAnticlockwise = makeIcon([arrow_anticlockwise, arrow_anticlockwise_2x])
export const ArrowClockwise = makeIcon([arrow_clockwise, arrow_clockwise_2x])
export const ArrowDown = makeIcon([arrow_down, arrow_down_2x])
export const ArrowLeft = makeIcon([arrow_left, arrow_left_2x])
export const ArrowRight = makeIcon([arrow_right, arrow_right_2x])
export const ArrowUp = makeIcon([arrow_up, arrow_up_2x])
export const At = makeIcon([at, at_2x])
export const BarChart = makeIcon([bar_chart, bar_chart_2x])
export const Barcode = makeIcon([barcode, barcode_2x])
export const Bell = makeIcon([bell, bell_2x])
export const Bell1 = makeIcon([bell_1, bell_1_2x])
export const BellFilled = makeIcon([bell_filled, bell_filled_2x])
export const BellFilledAlert = makeIcon([bell_filled_alert, bell_filled_alert_2x])
export const BellMuted = makeIcon([bell_muted, bell_muted_2x])
export const Bin = makeIcon([bin, bin_2x])
export const Bold = makeIcon([bold, bold_2x])
export const Bookmark = makeIcon([bookmark, bookmark_2x])
export const BookmarkClockFilled = makeIcon([bookmark_clock_filled, bookmark_clock_filled_2x])
export const BoxArrowTopRight = makeIcon([box_arrow_top_right, box_arrow_top_right_2x])
export const BulletList = makeIcon([bullet_list, bullet_list_2x])
export const Calendar = makeIcon([calendar, calendar_2x])
export const CalendarArrowLeft = makeIcon([calendar_arrow_left, calendar_arrow_left_2x])
export const CalendarArrowRight = makeIcon([calendar_arrow_right, calendar_arrow_right_2x])
export const CalendarTick = makeIcon([calendar_tick, calendar_tick_2x])
export const Campfire = makeIcon([campfire, campfire_2x])
export const Cancel = makeIcon([cancel, cancel_2x])
export const ChatBubble = makeIcon([chat_bubble, chat_bubble_2x])
export const ChevronDown = makeIcon([chevron_down, chevron_down_2x])
export const ChevronLeft = makeIcon([chevron_left, chevron_left_2x])
export const ChevronRight = makeIcon([chevron_right, chevron_right_2x])
export const ChevronUp = makeIcon([chevron_up, chevron_up_2x])
export const Circle = makeIcon([circle, circle_2x])
export const CircleFill = makeIcon([circle_fill, circle_fill_2x])
export const Clock = makeIcon([clock, clock_2x])
export const CloseRight = makeIcon([close_right, close_right_2x])
export const CloseLeft = makeIcon([close_left, close_left_2x])
export const Cloud = makeIcon([cloud, cloud_2x])
export const CloudStrikethrough = makeIcon([cloud_strikethrough, cloud_strikethrough_2x])
export const Code = makeIcon([code, code_2x])
export const CoffeeCup = makeIcon([coffee_cup, coffee_cup_2x])
export const Cog = makeIcon([cog, cog_2x])
export const CreditCard = makeIcon([credit_card, credit_card_2x])
export const Customise = makeIcon([customise, customise_2x])
export const Dashboard = makeIcon([dashboard, dashboard_2x])
export const Document = makeIcon([document, document_2x])
export const Download = makeIcon([download, download_2x])
export const DragAndDrop = makeIcon([drag_and_drop, drag_and_drop_2x])
export const Draft = makeIcon([draft, draft_2x])
export const Duplicate = makeIcon([duplicate, duplicate_2x])
export const EnvelopeClosed = makeIcon([envelope_closed, envelope_closed_2x])
export const Eye = makeIcon([eye, eye_2x])
export const Filter = makeIcon([filter, filter_2x])
export const Flag = makeIcon([flag, flag_2x])
export const Folder = makeIcon([folder, folder_2x])
export const Forward = makeIcon([forward, forward_2x])
export const FourDots = makeIcon([four_dots, four_dots_2x])
export const Github = makeIcon([github])
export const Google = makeIcon([google])
export const Group = makeIcon([group, group_2x])
export const Hamburger = makeIcon([hamburger, hamburger_2x])
export const Heart = makeIcon([heart, heart_2x])
export const HeartFill = makeIcon([heart_fill, heart_fill_2x])
export const Hidden = makeIcon([hidden, hidden_2x])
export const History = makeIcon([history, history_2x])
export const HourGlass = makeIcon([hour_glass, hour_glass_2x])
export const Image = makeIcon([image, image_2x])
export const ImportIcon = makeIcon([import_icon, import_icon_2x])
export const IndentLess = makeIcon([indent_less, indent_less_2x])
export const IndentMore = makeIcon([indent_more, indent_more_2x])
export const InfoCircle = makeIcon([info_circle, info_circle_2x])
export const Invoice = makeIcon([invoice, invoice_2x])
export const Italic = makeIcon([italic, italic_2x])
export const KeyboardShortcuts = makeIcon([keyboard_shortcuts, keyboard_shortcuts_2x])
export const Label = makeIcon([label, label_2x])
export const Lifebuoy = makeIcon([lifebuoy, lifebuoy_2x])
export const LineBreak = makeIcon([line_break, line_break_2x])
export const Link = makeIcon([link, link_2x])
export const Location = makeIcon([location, location_2x])
export const LogOut = makeIcon([log_out, log_out_2x])
export const MagnifyingGlass = makeIcon([magnifying_glass, magnifying_glass_2x])
export const Maximize = makeIcon([maximize, maximize_2x])
export const Merge = makeIcon([merge, merge_2x])
export const Minimize = makeIcon([minimize, minimize_2x])
export const Minus = makeIcon([minus, minus_2x])
export const Moon = makeIcon([moon, moon_2x])
export const MoveHorizontal = makeIcon([move_horizontal, move_horizontal_2x])
export const MoveVertical = makeIcon([move_vertical, move_vertical_2x])
export const Narrow = makeIcon([narrow, narrow_2x])
export const NumberedList = makeIcon([numbered_list, numbered_list_2x])
export const OneColumn = makeIcon([one_column, one_column_2x])
export const OpenBook = makeIcon([open_book, open_book_2x])
export const OpenLeft = makeIcon([open_left, open_left_2x])
export const OpenRight = makeIcon([open_right, open_right_2x])
export const Options = makeIcon([options, options_2x])
export const Padlock = makeIcon([padlock, padlock_2x])
export const PaperClip = makeIcon([paper_clip, paper_clip_2x])
export const PaperPlane = makeIcon([paper_plane, paper_plane_2x])
export const Parapgraph = makeIcon([parapgraph, parapgraph_2x])
export const Passkey = makeIcon([passkey])
export const Pencil = makeIcon([pencil, pencil_2x])
export const People = makeIcon([people, people_2x])
export const Phone = makeIcon([phone, phone_2x])
export const Pin = makeIcon([pin, pin_2x])
export const Pointer = makeIcon([pointer, pointer_2x])
export const Plus = makeIcon([plus, plus_2x])
export const Print = makeIcon([print, print_2x])
export const Products = makeIcon([products, products_2x])
export const QuestionCircle = makeIcon([question_circle, question_circle_2x])
export const QuestionMark = makeIcon([question_mark, question_mark_2x])
export const Quote = makeIcon([quote, quote_2x])
export const Replace = makeIcon([replace, replace_2x])
export const Reply = makeIcon([reply, reply_2x])
export const Save = makeIcon([save, save_2x])
export const Smiley = makeIcon([smiley, smiley_2x])
export const SpeechBubble = makeIcon([speech_bubble, speech_bubble_2x])
export const Square = makeIcon([square, square_2x])
export const StackedRectangles = makeIcon([stacked_rectangles, stacked_rectangles_2x])
export const Star = makeIcon([star, star_2x])
export const Sweep = makeIcon([sweep, sweep_2x])
export const Table = makeIcon([table, table_2x])
export const TextBigger = makeIcon([text_bigger, text_bigger_2x])
export const TextSize = makeIcon([text_size, text_size_2x])
export const TextSmaller = makeIcon([text_smaller, text_smaller_2x])
export const ThreeDots = makeIcon([three_dots, three_dots_2x])
export const Tick = makeIcon([tick, tick_2x])
export const TickCircle = makeIcon([tick_circle, tick_circle_2x])
export const Timezone = makeIcon([timezone, timezone_2x])
export const Triangle = makeIcon([triangle, triangle_2x])
export const Tt = makeIcon([tt, tt_2x])
export const Twitter = makeIcon([twitter])
export const TwoColumnsMinimized = makeIcon([two_columns_minimized, two_columns_minimized_2x])
export const TwoColumns = makeIcon([two_columns, two_columns_2x])
export const Unarchive = makeIcon([unarchive, unarchive_2x])
export const Underline = makeIcon([underline, underline_2x])
export const Upload = makeIcon([upload, upload_2x])
export const User = makeIcon([user, user_2x])
export const Wide = makeIcon([wide, wide_2x])
export const X = makeIcon([x, x_2x])
export const XCircle = makeIcon([x_circle, x_circle_2x])
export const XSmall = makeIcon([x_small, x_small_2x])
